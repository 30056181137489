<template>
  <div v-if="questionnaireRecord">
    <sheet-container
        ref="sheetContainer"
        :class="[
            'sheet-container',
            {
              'is-unfinished': !questionnaireRecord.finished
            }
        ]"
        :show-contents="false"
        :show-abstract.sync="showAbstract"
        :sheet-list="sheetList"
        :sheet-record-list="sheetRecordList"
        mode="view">
      <div slot="footer" class="padding-horizontal footer layout-vertical" v-if="questionnaireRecord.finished">
        <div class="layout-horizontal layout-middle margin-bottom" style="height: 48px;">
          <div class="layout-inflexible font-size-medium padding-left">
            <span @click="handleReadCommentClick" class="btn-read-comment">
              <span v-if="questionnaireRecord.commentCount > 0">查看点评</span>
              <span v-else><i class="far fa-comment"></i> 点评</span>
              <span v-if="questionnaireRecord.commentCount > 0">({{ questionnaireRecord.commentCount }})</span>
               <i class="fa fa-angle-right margin-left-small"></i></span>
          </div>
          <div class="layout-flexible"></div>
          <div class="layout-inflexible padding-right">
            <span
                class="btn-comment"
                :class="{
                'font-color-danger': questionnaireRecord.commentInfo && questionnaireRecord.commentInfo.thumbUpAdded,
                }"
                @click="handleThumbUpClick"
            >
              <i class="far fa-thumbs-up"></i>
              <span
                  v-if="questionnaireRecord.commentInfo && questionnaireRecord.commentInfo.thumbUpCount > 0"
                  class="btn-comment"
              >
                {{ questionnaireRecord.commentInfo.thumbUpCount }}
              </span>
            </span>
          </div>
        </div>
        <div v-for="comment in questionnaireRecord.commentList" :key="comment.id">
          <comment :comment="comment" @delete="handleCommentDeleteClick"></comment>
        </div>
        <div class="font-align-center margin-top" v-if="questionnaireRecord.commentList.length < questionnaireRecord.commentCount">
          <ys-button type="secondary" size="small" @click="handleMoreCommentClick">加载更多点评</ys-button>
        </div>
        <ys-textarea ref="commentInput" v-resize="handleCommentInputResize" placeholder="输入点评" class="margin-top"
                     v-model="commentMessageInputted" @keyup.enter.native="addComment"></ys-textarea>
        <ys-button class="layout-self-right margin-top margin-bottom-large" size="small" type="secondary"
                   @click="addComment">发送
        </ys-button>
      </div>
      <div slot="footer" v-else class="padding-horizontal-large footer layout-horizontal layout-middle padding-bottom-large">
        <div>问卷未填写</div>
        <ys-button lighting size="small" class="margin-left"
          @click="handleInputQuestionnaireClick">
          <span><i class="fa fa-pen font-size-extra-small"></i> 帮客户填写</span>
        </ys-button>
      </div>
    </sheet-container>
    <questionnaire-record-input-dialog
        :questionnaire-record-id="questionnaireRecordId"
        :visible.sync="inputDialogVisible"
        @confirm="handleQuestionnaireRecordInputConfirm"
    ></questionnaire-record-input-dialog>
  </div>
</template>

<script>
import SheetContainer from "@/components/sheet/SheetContainer";
import httpapi from "@/assets/javascript/httpapi";
import YsTextarea from "@/components/wedigets/YsTextarea";
import YsButton from "@/components/wedigets/YsButton";
import Comment from "@/components/comment/Comment";
import TextUtils from "@/assets/javascript/text-utils";
import QuestionnaireRecordInputDialog from "@/pages/questionnaire_record/panel/QuestionnaireRecordInputDialog";

function doLoadQuestionnaireRecord() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/questionnaire/record/get/with_sheet_records',
    data: {
      id: this.questionnaireRecordId,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.questionnaireRecord = res.data;
    this.sheetRecordList = this.questionnaireRecord.sheetRecordList;
    this.sheetList = this.questionnaireRecord.sheetRecordList.map(x => x.sheet);
    this.$emit('update', this.questionnaireRecord.id);
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doAddComment() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/v1/questionnaire/record/comment/add',
    data: {
      recordId: this.questionnaireRecord.id,
      organizationId: this.organizationId,
      message: this.commentMessageInputted,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    this.questionnaireRecord.commentCount++;
    this.commentMessageInputted = null;
    this.$refs.sheetContainer.scrollToBottom();
    this.busyLoadingCode = 0;
    doLoadCommentList.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败')
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doRemoveComment(comment) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/comment/remove',
    data: {
      id: comment.id,
      commentId: comment.commentId,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    for (let n = this.questionnaireRecord.commentList.length - 1; n >= 0; n--) {
      if (this.questionnaireRecord.commentList[n].id == comment.id) {
        this.questionnaireRecord.commentList.splice(n, 1);
        break;
      }
    }
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doAddThumbUp() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/v1/questionnaire/record/thumbup/add',
    data: {
      recordId: this.questionnaireRecord.id,
    }
  }).then(() => {
    if (loadingCode !== this.loadingCode) return;
    if (this.questionnaireRecord.commentInfo) {
      this.questionnaireRecord.commentInfo.thumbUpAdded = true;
      this.questionnaireRecord.commentInfo.thumbUpCount++;
    }
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doRemoveThumbUp() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/v1/questionnaire/record/thumbup/remove',
    data: {
      recordId: this.questionnaireRecord.id,
    }
  }).then(() => {
    if (loadingCode !== this.busyLoadingCode) return;
    if (this.questionnaireRecord.commentInfo) {
      this.questionnaireRecord.commentInfo.thumbUpAdded = false;
      this.questionnaireRecord.commentInfo.thumbUpCount--;
    }
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadCommentInfo() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/v1/questionnaire/record/comment/info/get',
    data: {
      recordId: this.questionnaireRecord.id,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.questionnaireRecord.commentInfo = res.data;
    this.questionnaireRecord.commentCount = res.data.commentCount;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadCommentList() {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/v1/questionnaire/record/comment/list/get/after',
    data: {
      recordId: this.questionnaireRecord.id,
      id: this.questionnaireRecord.commentList.length > 0 ? this.questionnaireRecord.commentList[this.questionnaireRecord.commentList.length - 1].id : null,
      count: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.questionnaireRecord.commentList, res.data);
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "QuestionnaireRecordPanel",
  mixins: [httpapi],
  components: {QuestionnaireRecordInputDialog, Comment, YsButton, YsTextarea, SheetContainer},
  props: {
    questionnaireRecordId: Number,
    userId: Number,
    organizationId: Number,
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,

      questionnaireRecord: null,
      sheetList: [],

      commentMessageInputted: null,

      showAbstract: true,

      inputDialogVisible: false,
    }
  },
  computed: {
    inputParams() {
      const {questionnaireRecordId, userId, organizationId} = this;
      return {
        questionnaireRecordId,
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.questionnaireRecord = null;
        this.sheetList = [];
        this.showAbstract = true;
        if (this.questionnaireRecordId) {
          this.loadQuestionnaireRecord();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadQuestionnaireRecord() {
      doLoadQuestionnaireRecord.bind(this)();
    },
    addComment() {
      if (TextUtils.isBlank(this.commentMessageInputted)) {
        return;
      }
      doAddComment.bind(this)();
    },
    handleCommentDeleteClick(comment) {
      doRemoveComment.bind(this)(comment);
    },
    handleThumbUpClick() {
      if (this.questionnaireRecord.commentInfo && this.questionnaireRecord.commentInfo.thumbUpAdded) {
        doRemoveThumbUp.bind(this)();
      } else {
        doAddThumbUp.bind(this)();
      }
    },
    handleCommentInputResize() {
      if(this.$refs.commentInput.focused) {
        this.$refs.sheetContainer.scrollToBottom();
      }
    },
    handleReadCommentClick() {
      this.$refs.sheetContainer.scrollToBottom();
    },
    handleInputQuestionnaireClick() {
      this.inputDialogVisible = true;
    },
    handleQuestionnaireRecordInputConfirm() {
      this.loadQuestionnaireRecord();
    },
    handleMoreCommentClick() {
      if(this.questionnaireRecord?.commentList.length > 0) {
        doLoadCommentList.bind(this)();
      }
    }
  }
}
</script>

<style scoped>

.footer {
  background-color: white;
  min-height: 48px;
}


.divider {
  background-color: #dadada;
  height: .5px;
}

.btn-read-comment {
  cursor: pointer;
}

.btn-comment:hover {
  cursor: pointer;
}

</style>