<template>
  <div class="layout-vertical margin-top" v-if="comment">
    <div class="comment-wrap layout-self-left margin-top-small" v-if="!comment.organizationId">
      <div class="comment-avatar__wrapper margin-right-small">
        <div class="comment-avatar layout-vertical layout-center">
          <el-image class="image-avatar image-small" fit="cover" :src="$prepareImageUrl(comment.account.avatar)"
                    v-if="comment.account.avatar"></el-image>
          <i class="fa fa-user" v-else></i>
        </div>
      </div>
      <div class="comment-item-left__wrapper">
        <div class="comment-item font-size-medium">
          <div>{{comment.message}}</div>
        </div>
      </div>
      <span class="btn-comment-delete" @click.stop="handleCommentDeleteClick"><i class="el-icon-close"></i></span>
    </div>
    <div class="comment-wrap layout-self-right margin-top-small" v-else>
      <span class="btn-comment-delete" @click.stop="handleCommentDeleteClick"><i class="el-icon-close"></i></span>
      <div class="comment-item-right__wrapper layout-self-right">
        <div class="comment-item font-size-medium">{{comment.message}}</div>
      </div>
      <div class="comment-avatar__wrapper margin-left-small">
        <div class="comment-avatar layout-vertical layout-center">
          <el-image class="image-avatar image-small" fit="cover" :src="$prepareImageUrl(comment.account.avatar)"
                    v-if="comment.account.avatar"></el-image>
          <i class="fa fa-user" v-else></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";

export default {
  name: "Comment",
  mixins: [httpapi],
  props: {
    comment: Object,
  },
  methods: {
    handleCommentDeleteClick() {
      this.$emit('delete', this.comment);
    }
  }
}
</script>

<style scoped>

.comment {
  padding: 8px 0px;
}

.comment-content {
  display: inline-block;
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 10px;
}

.comment-direct__to {
  text-align: right;
}

.comment-content__wrapper {
  display: inline-block;
  vertical-align: bottom;
}

.comment-direct__to .comment-content__wrapper:after {
  content: "";
  width: 0;
  height: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 8px solid #f1f1f1;
  border-top: 10px solid transparent;
  display: inline-block;
  position: relative;
  top: 6px;
}

.comment-direct__from .comment-content__wrapper:before {
  content: "";
  width: 0;
  height: 0;
  border-right: 0;
  border-bottom: 0;
  border-right: 8px solid #f1f1f1;
  border-top: 10px solid transparent;
  display: inline-block;
  position: relative;
  top: 6px;
}

.avatar-container {
  width: 24px;
  height: 24px;
  background-color: #f1f1f1;
  border-radius: 50%;
  display: inline-block;
  vertical-align: bottom;
}

.comment-direct__to .avatar-container {
  margin-left: 8px;
}

.comment-direct__from .avatar-container {
  margin-right: 8px;
}

.comment {
  padding: 4px 8px;
  border-radius: 100px;
  color: #686868;
}

.btn-thumbs-up, .btn-comment {
  cursor: pointer;
}

.btn-thumbs-up:hover, .btn-comment:hover {
  color: #3a8ee6;
}

.comment-item {
  padding: 8px 16px;
  border-radius: 8px;
  display: inline-block;
  background-color: #f1f1f1;
  max-width: 240px;
}

.comment-item-left__wrapper, .comment-item-right__wrapper {
  display: inline-block;
  cursor: pointer;
  color: #1f1f1f;
}

.comment-item-left__wrapper:hover, .comment-item-right__wrapper:hover {
  color: #3a8ee6;
}

.comment-item-left__wrapper:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 0px;
  border-right: 8px solid #f1f1f1;
  border-bottom: 0px;
  border-top: 10px solid transparent;
  display: inline-block;
  position: relative;
  top: 6px;
}

.comment-item-right__wrapper:after {
  content: "";
  width: 0;
  height: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 8px solid #f1f1f1;
  border-top: 10px solid transparent;
  display: inline-block;
  position: relative;
  top: 6px;
}

.comment-avatar__wrapper {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: bottom;
}

.comment-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  color: #bec0c0;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.btn-comment-delete {
  padding: 0px 8px;
  border-radius: 8px;
  border: 1px solid #dadada;
  vertical-align: top;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  visibility: hidden;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  color: #7d7c7c;
  line-height: 13px;
  font-size: 13px;
}

.btn-comment-delete:hover {
  background-color: #f091a6;
  border: 1px solid #f091a6;
  box-shadow: 0 0 4px #f091a6;
  color: white;
}

.comment-wrap:hover .btn-comment-delete {
  visibility: visible;
}

</style>