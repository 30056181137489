<template>
  <el-dropdown class="ys-dropdown" popper-class="ys-dropdown" v-bind="$attrs">
    <div class="reference">
      <slot></slot>
    </div>
    <slot slot="dropdown" name="dropdown"></slot>
  </el-dropdown>
</template>

<script>
export default {
  name: "YsDropdown"
}
</script>

<style scoped>

.reference {
  cursor: pointer;
}

.reference:hover {
  filter: brightness(95%);
}
</style>
<style>
.el-popper[x-placement^=left] .popper__arrow {
  display: none;
}

.el-popper[x-placement^=right] .popper__arrow {
  display: none;
}

.el-popper[x-placement^=bottom] .popper__arrow {
  display: none;
}

.el-popper[x-placement^=top] .popper__arrow {
  display: none;
}

.el-popper {
  border-radius: 8px;
  min-width: 220px;
}
</style>