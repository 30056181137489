<template>
  <div class="page layout-horizontal">
    <div class="layout-vertical layout-inflexible" style="height: 100%; ">
      <div class="padding padding-large font-align-right">
        <ys-button
            @click="clickAddQuestionnaireRecord"
        >+ 发送问卷
        </ys-button>
      </div>
      <div class="layout-flexible margin-bottom" style="overflow: hidden;">
        <ys-infinite-scroll-view
            @loadmore="loadMore"
            style="width: 400px; height: 100%;"
        >
          <div
              v-for="(questionnaireRecord, index) in questionnaireRecordList"
              :key="questionnaireRecord.id"
              class="margin-horizontal"
          >
            <div
                v-if="index == 0 || questionnaireRecord.procedureState !== questionnaireRecordList[index - 1].procedureState"
                class="padding font-color-placeholder">
          <span
              v-if="questionnaireRecord.procedureState != null">{{
              ProcedureUtils.getStateName(questionnaireRecord.procedureState)
            }}</span>
              <span v-else>更早记录</span>
            </div>
            <div
                :class="[
                'list-item font-size-medium layout-horizontal layout-middle',
                {
                  'is-active': currentQuestionnaireRecord && currentQuestionnaireRecord.id === questionnaireRecord.id
                }
                ]"
                @click="clickQuestionnaireRecord(questionnaireRecord)"
            >
              <span class="far fa-clipboard layout-inflexible"></span>
              <div class="margin-left layout-flexible">
                <div class=" layout-flexible">
                  <span>{{ questionnaireRecord.title }}</span>
                  <span class="font-color-danger font-size-small" v-if="!questionnaireRecord.finished"> [未填写]</span>
                </div>
                <div class="font-size-small font-color-secondary" v-if="questionnaireRecord.finished">
                  {{ TimeUtils.format('填写时间：yyyy/MM/dd HH:mm', questionnaireRecord.finishedAt) }}
                </div>
              </div>
              <ys-dropdown>
                <ys-button round type="text">
                  <span class="fas fa-ellipsis-h font-color-placeholder"></span>
                </ys-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                      class="font-color-danger padding-horizontal-large padding-vertical font-size-medium"
                      @click.native="clickDelete(questionnaireRecord)"
                  >
                    <span class="far fa-trash-alg"></span>
                    <span>删除问卷</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </ys-dropdown>
            </div>
          </div>
        </ys-infinite-scroll-view>
      </div>
    </div>
    <questionnaire-record-panel
        v-if="currentQuestionnaireRecord"
        :organization-id="organizationId"
        :user-id="userId"
        :questionnaire-record-id="currentQuestionnaireRecord.id"
        style="max-width: 380px;"
        class="layout-flexible"
        @update="handleQuestionnaireRecordUpdate"
    ></questionnaire-record-panel>
    <div v-else class="padding padding-large font-size-large font-color-light-placeholder">未选择问卷</div>
    <questionnaire-picker-dialog
        :organization-id="organizationId"
        title="发送问卷"
        :visible.sync="questionnairePickerDialogVisible"
        @pick="handleQuestionnairePicked"
    ></questionnaire-picker-dialog>
    <delete-dialog
        v-if="deletingQuestionnaireRecord"
        :visible.sync="deleteDialogVisible"
        title="删除问卷"
        :message="`您确定要删除${deletingQuestionnaireRecord.finished ? '用户已填写的' : ''}${deletingQuestionnaireRecord.title}吗？`"
        @confirm="confirmDelete"
    ></delete-dialog>
  </div>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import YsDropdown from "@/components/wedigets/YsDropdown";
import YsButton from "@/components/wedigets/YsButton";
import {ProcedureUtils} from "@/assets/javascript/procedure-utils";
import QuestionnaireRecordPanel from "@/pages/questionnaire_record/panel/QuestionnaireRecordPanel";
import QuestionnairePickerDialog from "@/components/dialog/QuestionnairePickerDialog";
import DeleteDialog from "@/components/dialog/DeleteDialog";
import {TimeUtils} from "@/assets/javascript/time-utils";

export default {
  name: "QuestionnairePanel",
  mixins: [httpapi],
  components: {
    DeleteDialog,
    QuestionnairePickerDialog, QuestionnaireRecordPanel, YsButton, YsDropdown, YsInfiniteScrollView
  },
  props: {
    organizationId: Number,
    userId: Number,
  },
  data() {
    return {
      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 常量
       */
      ProcedureUtils: ProcedureUtils,
      TimeUtils: TimeUtils,

      /**
       * 问卷列表
       */
      questionnaireRecordList: [],
      pageNum: 1,
      pageSize: 20,
      totalPages: 0,

      /**
       * 当前选择的问卷
       */
      currentQuestionnaireRecord: null,

      /**
       * 发送问卷
       */
      questionnairePickerDialogVisible: false,

      /**
       * 删除问卷
       */
      deletingQuestionnaireRecord: null,
      deleteDialogVisible: false,
    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.questionnaireRecordList = [];
        this.pageNum = 1;
        this.totalPages = 0;
        this.currentQuestionnaireRecord = null;
        this.loadQuestionnaireRecordList();
      },
      immediate: true,
    },
    currentQuestionnaireRecord: {
      handler: function (currentQuestionnaireRecord) {
        if (currentQuestionnaireRecord) {
          this.setQuestionnaireRecordRead(currentQuestionnaireRecord);
        }
      },
      immediate: true,
    }
  },
  methods: {
    clickQuestionnaireRecord: function (questionnaireRecord) {
      this.currentQuestionnaireRecord = questionnaireRecord;
    },
    clickAddQuestionnaireRecord: function () {
      this.questionnairePickerDialogVisible = true;
    },
    handleQuestionnairePicked: function (questionnaireList) {
      this.sendQuestionnaire(questionnaireList[0]);
    },
    clickDelete: function (questionnaireRecord) {
      this.deletingQuestionnaireRecord = questionnaireRecord;
      this.deleteDialogVisible = true;
    },
    confirmDelete: function () {
      this.deleteQuestionnaireRecord(this.deletingQuestionnaireRecord);
    },
    handleQuestionnaireRecordUpdate: function (id) {
      this.loadQuestionnaireRecord(id);
    },
    isBusy: function () {
      return this.busyLoadingCode === this.loadingCode;
    },
    loadQuestionnaireRecordList(pageNum = 1) {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;
      this.$reqGet({
        path: `/questionnaire/record/list/${this.organizationId}/${this.userId}/${pageNum}/${this.pageSize}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let questionnaireRecordList = pageNum == 1 ? [] : this.questionnaireRecordList;
            this.$appendAfter(questionnaireRecordList, res.data.list);
            this.questionnaireRecordList = questionnaireRecordList;
            this.pageNum = res.data.pageNum;
            this.totalPages = res.data.pages;
            if (!this.currentQuestionnaireRecord && questionnaireRecordList.length > 0) {
              this.currentQuestionnaireRecord = questionnaireRecordList[0];
            }
          })
          .catch(() => {
            this.$message.error('加载失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    },
    loadMore: function () {
      if (this.pageNum < this.totalPages) {
        this.loadQuestionnaireRecordList(this.pageNum + 1);
      }
    },
    loadQuestionnaireRecord: function (id) {
      this.$reqGet({
        path: `/questionnaire/record/${id}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.$appendBefore(this.questionnaireRecordList, [res.data]);
          })
          .catch(() => this.$message.error('加载失败'));
    },
    sendQuestionnaire: function (questionnaire) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqPost({
        path: `/questionnaire/record/${this.organizationId}/${this.userId}/${questionnaire.id}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let questionnaireRecord = res.data;
            this.$appendBefore(this.questionnaireRecordList, [questionnaireRecord]);
            this.currentQuestionnaireRecord = questionnaireRecord;
          })
          .catch(() => {
            this.$message.error('操作失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    },
    deleteQuestionnaireRecord: function (questionnaireRecord) {
      if (this.isBusy()) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqDelete({
        path: `/questionnaire/record/${questionnaireRecord.id}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            let deleteIndex = -1;
            for (let n = this.questionnaireRecordList.length - 1; n >= 0; n--) {
              if (this.questionnaireRecordList[n].id === questionnaireRecord.id) {
                deleteIndex = n;
                this.questionnaireRecordList.splice(n, 1);
                break;
              }
            }
            if (this.currentQuestionnaireRecord?.id === questionnaireRecord.id) {
              if (this.questionnaireRecordList.length > 0) {
                if (deleteIndex > 0) {
                  this.currentQuestionnaireRecord = this.questionnaireRecordList[deleteIndex - 1];
                } else {
                  this.currentQuestionnaireRecord = this.questionnaireRecordList[0];
                }
              } else {
                this.currentQuestionnaireRecord = null;
              }
            }
          })
          .catch(() => {
            this.$message.error('操作失败');
          })
          .complete(() => {
            this.busyLoadingCode = 0;
          })
    },
    setQuestionnaireRecordRead: function (questionnaireRecord) {
      this.$reqPatch({
        path: `/questionnaire/record/read/${questionnaireRecord.id}`
      });
    }
  }

}
</script>

<style scoped>

.list-item {
  width: auto;
  min-width: unset;
}

.list-item.is-active {
  background-color: #faf4f5;
  border: 1px solid #ff5500;
}

</style>