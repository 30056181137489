<template>
  <ys-dialog :title="title" :visible.sync="isVisible" @update:visible="visible=>$emit('update:visible', visible)">
    <div class="layout-vertical padding-horizontal">
      <el-collapse-transition>
        <div v-if="selectedQuestionnaireList.length > 0">
          <div class="margin-top">已选择：</div>
          <div class="layout-horizontal layout-wrap">
            <div class="margin selected-questionnaire" v-for="selectedQuestionnaire in selectedQuestionnaireList"
                 :key="selectedQuestionnaire.id"
                 @click="handleQuestionnaireClick(selectedQuestionnaire)"
            >
              <span>{{ selectedQuestionnaire.title }}</span>
              <i class="el-icon-close font-size-small btn-delete"></i>
            </div>
          </div>
        </div>
      </el-collapse-transition>
      <div class="margin-top">
        <ys-infinite-scroll-view style="height: 50vh;" @loadmore="loadMore">
          <div class="list-item layout-horizontal padding layout-middle" v-for="questionnaire in questionnaireList"
               :key="questionnaire.id" @click="handleQuestionnaireClick(questionnaire)">
            <ys-checker :value="questionnaire.__selected" :disable="questionnaire.__disable" class="layout-inflexible"></ys-checker>
            <div class="questionnaire-icon layout-inflexible margin-left-large">
              <i class="fas fa-clipboard font-color-secondary font-size-large"></i>
            </div>
            <div class="margin-left layout-flexible" style="overflow: hidden">
              <div class="font-size-medium">{{ questionnaire.title }}</div>
              <div class="font-color-secondary font-nowrap">{{ questionnaire.message }}</div>
            </div>
          </div>
        </ys-infinite-scroll-view>
      </div>
    </div>
    <div slot="footer">
      <ys-button type="secondary" @click="handleCancelClick">取消</ys-button>
      <ys-button class="margin-left" @click="handleConfirmClick">
        <span>确定</span>
        <span v-if="selectedQuestionnaireList.length > 0">({{selectedQuestionnaireList.length}}/{{maxSelectedCount}})</span>
      </ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import YsChecker from "@/components/wedigets/YsChecker";
import httpapi from "@/assets/javascript/httpapi";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import YsButton from "@/components/wedigets/YsButton";

function doLoadQuestionnaireList(pageNum) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/questionnaire/list/get',
    data: {
      organizationId: this.organizationId,
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.$appendAfter(this.questionnaireList, res.data.list);
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
    this.refreshQuestionnaireSelectionStates();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "QuestionnairePickerDialog",
  mixins: [httpapi],
  components: {YsButton, YsInfiniteScrollView, YsChecker, YsDialog},
  props: {
    organizationId: Number,
    maxSelectedCount: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: '选择问卷',
    },
    visible: Boolean,
    defaultSelectedQuestionnaireIds: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      isVisible: false,
      loadingCode: 1,
      busyLoadingCode: 0,
      questionnaireList: [],
      selectedQuestionnaireList: [],
      pageNum: 1,
      totalPages: 0,
    }
  },
  watch: {
    visible: {
      handler() {
        this.selectedQuestionnaireList = [];
        this.isVisible = this.visible;
        this.refreshQuestionnaireSelectionStates();
      },
      immediate: true,
    },
    organizationId: {
      handler() {
        this.loadingCode++;
        this.questionnaireList = [];
        this.pageNum = 1;
        this.totalPages = 0;
        if (this.organizationId) {
          this.loadQuestionnaireList();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadQuestionnaireList(pageNum = 1) {
      doLoadQuestionnaireList.bind(this)(pageNum);
    },
    loadMore() {
      if (this.pageNum < this.totalPages) {
        this.loadQuestionnaireList(this.pageNum + 1);
      }
    },
    handleQuestionnaireClick(questionnaire) {
      if(questionnaire.__disable) return;
      let selectedIndex = this.doGetSelectedIndex(questionnaire);
      if(selectedIndex >= 0) {
        this.selectedQuestionnaireList.splice(selectedIndex, 1);
      } else {
        if(this.selectedQuestionnaireList.length < this.maxSelectedCount) {
          this.selectedQuestionnaireList.push(questionnaire);
        }
      }
      this.refreshQuestionnaireSelectionStates();
      if(this.selectedQuestionnaireList.length == 1 && this.maxSelectedCount == 1) {
        this.handleConfirmClick();
      }
    },
    handleCancelClick() {
      this.isVisible = false;
      this.$emit('update:visible', false);
    },
    handleConfirmClick() {
      if(this.selectedQuestionnaireList.length == 0) {
        this.$message.warning('至少选择一个问卷');
        return;
      }
      this.isVisible = false;
      this.$emit('update:visible', false);
      this.$emit('pick', this.selectedQuestionnaireList);
    },
    doGetSelectedIndex(questionnaire) {
      for(let n = 0; n < this.selectedQuestionnaireList.length; n++) {
        if(this.selectedQuestionnaireList[n].id === questionnaire.id) {
          return n;
        }
      }
      return -1;
    },
    refreshQuestionnaireSelectionStates() {
      for (let questionnaire of this.questionnaireList) {
        let selected = false;
        let disable = false;
        for (let defaultSelectedId of this.defaultSelectedQuestionnaireIds) {
          if (defaultSelectedId === questionnaire.id) {
            selected = true;
            disable = true;
            break;
          }
        }
        if (!disable) {
          for (let selectedQuestionnaire of this.selectedQuestionnaireList) {
            if (selectedQuestionnaire.id === questionnaire.id) {
              selected = true;
              break;
            }
          }
        }
        questionnaire.__selected = selected;
        questionnaire.__disable = disable;
      }
    }
  }
}

</script>

<style scoped>

.list-item {
  width: auto;
}

.selected-questionnaire {
  background-color: #f091a6;
  color: white;
  padding: 4px 16px;
  border-radius: 100px;
  cursor: pointer;
  margin: 4px;
}

.selected-questionnaire .btn-delete {
  opacity: 0;
}

.selected-questionnaire:hover .btn-delete {
  opacity: 1;
}

</style>