import GuidUtils from "@/assets/javascript/guid-utils";

export const ProcedureState = {

    /**
     * 筛查
     */
    INVESTIGATION: 0,

    /**
     * 制定处方
     */
    MAKE_PRESCRIPTION: 1,

    /**
     * 打卡监督
     */
    PUNCH: 2,

    /**
     * 评估
     */
    ESTIMATE: 3,

    /**
     * 调整
     */
    ADJUST: 4,

    /**
     * 完成
     */
    COMPLETE: 5,

    /**
     * 结束
     */
    END: 6

}

export const ProcedureStageTimeLine = {

    /**
     * 过去
     */
    PASSED: -1,

    /**
     * 现在
     */
    CURRENT: 0,

    /**
     * 将来
     */
    FUTURE: 1,
}

function getStateName(state) {
    switch (state) {
        case ProcedureState.INVESTIGATION:
            return "营养筛查";
        case ProcedureState.MAKE_PRESCRIPTION:
            return "制定处方";
        case ProcedureState.PUNCH:
            return "打卡监督";
        case ProcedureState.ESTIMATE:
            return "评估";
        case ProcedureState.ADJUST:
            return "调整";
        case ProcedureState.COMPLETE:
            return "完成";
        case ProcedureState.END:
            return "结束"
    }
}

/**
 * 将state转换为常规步骤
 * @param state
 * @returns {number}
 */
function convertState2NormalStep(state) {
    switch (state) {
        case ProcedureState.INVESTIGATION:
            return 0;
        case ProcedureState.MAKE_PRESCRIPTION:
            return 1;
        case ProcedureState.PUNCH:
            return 2;
        case ProcedureState.ESTIMATE:
            return 3;
        case ProcedureState.ADJUST:
            return 4;
        case ProcedureState.COMPLETE:
            return 5;
        case ProcedureState.END:
            return 6;
        default:
            return -1;
    }
}


function insertFutureStages(stages) {
    if (stages.length === 0) return;

    let timeline = ProcedureStageTimeLine.PASSED;
    let insertFuture = true;
    for (let n = 0; n < stages.length; n++) {
        let stage = stages[n];
        if (typeof stage.timeline != 'undefined') {
            timeline = stage.timeline;
        } else if (n == stages.length - 1) {
            timeline = ProcedureStageTimeLine.CURRENT;
        }
        stage.timeline = timeline;

        if (!stage.globalId) stage.globalId = GuidUtils.guid();

        switch (stage.state) {
            case ProcedureState.ADJUST:
            case ProcedureState.COMPLETE:
            case ProcedureState.END:
                insertFuture = false;
                break;
        }
    }

    if (timeline === ProcedureStageTimeLine.FUTURE) return;
    if (!insertFuture) return;

    let lastStage = stages[stages.length - 1];
    if (convertState2NormalStep(lastStage.state) < convertState2NormalStep(ProcedureState.MAKE_PRESCRIPTION)) {
        stages.push({
            globalId: GuidUtils.guid(),
            state: ProcedureState.MAKE_PRESCRIPTION,
            timeline: ProcedureStageTimeLine.FUTURE
        });
    }
    if (convertState2NormalStep(lastStage.state) < convertState2NormalStep(ProcedureState.PUNCH)) {
        stages.push({
            globalId: GuidUtils.guid(),
            state: ProcedureState.PUNCH,
            timeline: ProcedureStageTimeLine.FUTURE
        })
    }
    if (convertState2NormalStep(lastStage.state) < convertState2NormalStep(ProcedureState.ESTIMATE)) {
        stages.push({
            globalId: GuidUtils.guid(),
            state: ProcedureState.ESTIMATE,
            timeline: ProcedureStageTimeLine.FUTURE
        })
    }
    if (convertState2NormalStep(lastStage.state) < convertState2NormalStep(ProcedureState.ADJUST)) {
        stages.push({
            globalId: GuidUtils.guid(),
            state: ProcedureState.ADJUST,
            timeline: ProcedureStageTimeLine.FUTURE
        })
    }


}

export const ProcedureUtils = {
    getStateName,
    insertFutureStages,
}